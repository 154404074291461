<script lang="ts" setup>
const { t } = useI18n();

const deferredPrompt = ref();
const available = ref(false);

const onClick = async () => {
  if (deferredPrompt.value) {
    deferredPrompt.value.prompt();

    const { outcome } = await deferredPrompt.value.userChoice;
    if (outcome === 'accepted') {
      deferredPrompt.value = null;
    }
  }
};

onMounted(() => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();

    deferredPrompt.value = e;
    available.value = !!e;
  });
});
</script>

<template>
  <section
    class="relative py-16 px-4 md:px-5 overflow-hidden"
    :class="{ hidden: !available }"
  >
    <NuxtImg
      src="/images/install-app-background.jpg"
      width="1000"
      height="667"
      sizes="md:100vw lg:100vw xl:100vw xxl:100vw"
      format="webp"
      loading="lazy"
      :alt="t('preview_alt')"
      class="page-image-preview"
    />
    <div class="page-overflow-6 section-top-shadow section-bottom-shadow" />

    <div class="container mx-auto text-center my-6 relative z-1">
      <h2
        v-show-on-visible
        class="text-2xl md:text-5xl text-800 text-center mb-8 mt-8 relative z-1"
      >
        {{ t('title') }}
      </h2>
      <p
        v-show-on-visible
        class="mt-0 mb-12 lg:text-lg leading-6 text-center mx-auto text-white"
      >
        {{ t('description') }}
      </p>
      <div v-show-on-visible>
        <Button @click="onClick" rounded size="large" class="px-8">
          {{ t('action') }}
        </Button>
        <p class="text-xs mt-5 mb-8 leading-6 text-white">
          {{ t('hint') }}
        </p>
      </div>
      <!--      <div class="flex justify-center items-center">-->
      <!--        <a href="https://www.apple.com" class="text-white mr-3">-->
      <!--          <i class="pi pi-apple text-2xl" /></a><a href="https://play.google.com" class="text-white mr-3"><i class="pi pi-android text-2xl" /></a><a href="https://www.facebook.com" class="text-white"><i class="pi pi-facebook text-2xl" /></a>-->
      <!--      </div>-->
    </div>
  </section>
</template>

<i18n>
en:
  preview_alt: Background for the section with the installation of an erotic application
  title: Install App
  description: Install the application on your device and get unlimited pleasure from our site even faster.
  action: Install
  hint: Works in Chrome, Safari and other browsers

ru:
  preview_alt: Фон для секции с установкой эротического приложения
  title: Установите приложение
  description: Установите приложение себе на устройство и получите безграничное удовольствие нашего сайта еще быстрее.
  action: Установить
  hint: Работает в Chrome, Safari и других браузерах
</i18n>
