<script lang="ts" setup>
const route = useRoute();
const router = useRouter();
const store = useMainStore();
const { t } = useI18n();

if (store.authorized && !route.query.force) {
  await navigateTo('/dashboard', { redirectCode: 302 });
}

watch(
  () => route.query.force,
  (v) => {
    if (store.authorized && !v) router.push('/dashboard');
  },
);

useSchemaOrg([
  defineWebPage({
    name: t('schema_title'),
    datePublished: new Date(2022, 4, 27, 20).toISOString(),
  }),
]);
</script>

<template>
  <main data-test-id="main-page">
    <SectionMainWelcome />
    <SectionMainProducts />
    <SectionMainCommentaries />
    <ClientOnly>
      <SectionMainInstallApp />
    </ClientOnly>
    <LayoutFooter />
  </main>
</template>

<i18n>
en:
  schema_title: Home

ru:
  schema_title: Главная
</i18n>
